.footerContainer {
    background-color: whitesmoke;
    margin-top: 3rem;
}

.footerCopyright {
    text-align: center;
    padding: 0.75rem;
    margin-top: 1rem;
}

.contactLinks {
    padding-top: 2rem;
    justify-content: center;

    img {
        width: 2.5rem;
        margin: 1rem;
    }
}